import React from 'react';
import { Typography, Avatar } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import { useApp } from 'App';
import SidebarItem from './SidebarItem';
import { useSelector } from 'store/redux/selector';
import { SIDEBAR_WIDTH } from 'constants/constants';
import { ViewModule } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: SIDEBAR_WIDTH,
    backgroundColor: theme.palette.secondary.dark,
    '@media print': {
      display: 'none',
    },
  },
  drawerHeader: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    height: 64,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
  },
  avatar: {
    width: 40,
    height: 40,
    border: `2px solid ${theme.palette.primary.light}`,
  },
  content: {
    transition: 'opacity 0.4s ease',
    opacity: 1,
  },
  accountContent: {
    padding: '10px 15px 15px 0',
  },
}));

const Sidebar: React.FC = () => {
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const app = useApp();
  const restaurant = useSelector(state => state.restaurant);

  return (
    <Drawer
      variant={app.isMobile || app.windowWidth < 1280 ? 'temporary' : 'persistent'}
      anchor="left"
      classes={{ paper: classes.drawerPaper }}
      open={app.isOpenedMenu}
      onClose={app.handleOpenMenu}
    >
      <div className={classes.content}>
        <div className={classes.drawerHeader}>
          <Typography variant="h6">SGrande Garçom</Typography>
        </div>
        <SidebarItem to="/board-management" icon={<ViewModule />} text="mesas" nested />
        <SidebarItem to="/board-orders" icon={<AssignmentIcon />} text="pedidos mesa" nested />
        <SidebarItem to="/delivery-orders" icon={<AssignmentIcon />} text="pedidos delivery" nested />
      </div>
      <div className={classes.accountContent}>
        <SidebarItem
          to="/account"
          icon={
            user.image ? (
              <Avatar className={classes.avatar} src={user.image.imageThumbUrl} />
            ) : user.name ? (
              <Avatar className={classes.avatar}>{user.name.charAt(0)}</Avatar>
            ) : (
              <Avatar className={classes.avatar} />
            )
          }
          text={user.name || 'Minha conta'}
          secondaryText={restaurant?.name}
        />
      </div>
    </Drawer>
  );
};

export default Sidebar;
