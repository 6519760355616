import React, { MouseEvent } from 'react';
import { Typography, makeStyles, ListItem, IconButton, alpha } from '@material-ui/core';
import { OrderList } from 'pages/orders/types/orderList';
import NewIcon from '@material-ui/icons/NewReleases';
import { MoreVert } from '@material-ui/icons';
import { useOrders } from 'pages/board-orders/hook/useOrders';
import history from 'services/history';

type OrderItemModuleProps = {
  order: OrderList;
  setAnchorEl(el: HTMLButtonElement): void;
};

const useStyles = makeStyles(theme => ({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: theme.shape.borderRadius,
  },
  listItemOrderNew: {
    backgroundColor: '#ffffe0',
  },
  status: {
    padding: '2px 10px',
    borderRadius: theme.shape.borderRadius,
    marginLeft: 6,
  },
  orderStatus: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
  },
  icon: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  dateDistance: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  address: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: 250,
    },
  },
  customerName: {
    marginTop: 10,
  },
  total: {
    marginTop: 10,
    fontSize: 18,
  },
  p: { backgroundColor: '#17a2b8', color: '#fff' },
  o: { backgroundColor: '#ffc107' },
  a: { backgroundColor: '#8BC34A', color: '#fff' },
  d: { backgroundColor: '#007bff', color: '#fff' },
  c: { backgroundColor: '#6c757d', color: '#fff' },
  x: { backgroundColor: '#dc3545', color: '#fff' },
  timer: {
    padding: '1px 5px',
    backgroundColor: alpha(theme.palette.success.main, 0.2),
    color: theme.palette.success.main,
    width: 60,
    marginLeft: 5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'center',
  },
  timerLate: {
    backgroundColor: alpha(theme.palette.error.main, 0.2),
    color: theme.palette.error.main,
  },
}));

const OrderItemModule: React.FC<OrderItemModuleProps> = ({ order, setAnchorEl }) => {
  const classes = useStyles();
  const { setSelectedOrder } = useOrders();

  function handleMoreClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setSelectedOrder(order);
  }

  function handleOrderClick(order: OrderList) {
    history.push(`/board-management/movements/${order.board_movement_id}`);
  }

  return (
    <>
      <ListItem
        key={order.id}
        className={order.isNew ? `${classes.listItem} ${classes.listItemOrderNew}` : classes.listItem}
        button
        onClick={() => handleOrderClick(order)}
      >
        <div className={classes.orderStatus}>
          {order.isNew && <NewIcon color="primary" />}

          {['o', 'a', 'd'].includes(order.status) && (
            <div className={order.isLate ? `${classes.timer} ${classes.timerLate}` : classes.timer}>
              <Typography variant="caption">{order.dateDistance}</Typography>
            </div>
          )}

          <div className={`${classes.status} ${classes[order.status]}`}>
            <Typography variant="caption">{order.statusText}</Typography>
          </div>
        </div>

        <Typography variant="body2" color="primary">
          Pedido {order.formattedSequence}
        </Typography>

        <Typography variant="h6" className={classes.customerName}>
          Mesa {order.board_movement?.board?.number}
        </Typography>

        <Typography>{order.customer_name}</Typography>

        <Typography variant="body2" color="textSecondary">
          {order.formattedCreatedAt}
        </Typography>
        <Typography className={classes.total}>{order.formattedTotal}</Typography>
        <IconButton className={classes.icon} onClick={handleMoreClick}>
          <MoreVert />
        </IconButton>
      </ListItem>
    </>
  );
};

export default OrderItemModule;
