import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { moneyFormat } from 'helpers/NumberFormat';
import { useMessaging } from 'hooks/messaging';
import { Order } from 'types/order';

const useStyles = makeStyles({
  container: {},
});

interface OrderResumePaymentMethodProps {
  order: Order;
}

function OrderResumePaymentMethod({ order }: OrderResumePaymentMethodProps) {
  const classes = useStyles();
  const messaging = useMessaging();

  function handleCopyToClipboard() {
    if (!order?.pix_payment?.qr_code) {
      return;
    }

    navigator.clipboard.writeText(order.pix_payment.qr_code).then(
      () => {
        messaging.handleOpen('Código QR copiado!');
      },
      err => console.error(err)
    );
  }

  return (
    <>
      {order?.payment_method && (
        <div className={classes.container}>
          <Typography color="primary" variant="h6">
            Forma de pagamento
          </Typography>
          <div>
            {order.payment_method.mode === 'online' ? (
              <Typography>Pagamento online</Typography>
            ) : (
              <Typography>Pagamento na entrega</Typography>
            )}
            <Typography>{order.payment_method.method}</Typography>
            {order.payment_method.kind === 'money' && order.change > 0 && (
              <Typography color="textSecondary">
                Troco para {moneyFormat(order.change)} ({moneyFormat(order.change - order.total)})
              </Typography>
            )}
            {order.payment_method.kind === 'pix' && !!order.pix_payment && (
              <Button onClick={handleCopyToClipboard} variant="contained" color="primary" size="small">
                Copiar Código QR
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default OrderResumePaymentMethod;
