import React from 'react';
import { Button } from '@material-ui/core';
import CustomDialog, { DialogConsumer } from 'components/dialog/CustomDialog';
import OrderResumeCustomer from '../registration/resume/OrderResumeCustomer';
import OrderResumePaymentMethod from '../registration/resume/OrderResumePaymentMethod';
import OrderResumeShipment from '../registration/resume/OrderResumeShipment2';
import { makeStyles } from '@material-ui/core/styles';
import history from 'services/history';
import OrderPreviewProductList from './OrderPreviewProductList';
import PropTypes from 'prop-types';
import OrderPreviewTotal from './OrderPreviewTotal';
import { useOrders } from '../hook/useOrders';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  status: {
    padding: '2px 10px',
    borderRadius: 3,
    marginBottom: 7,
    display: 'flex',
    justifyContent: 'center',
  },
  o: { backgroundColor: '#ffc107' },
  a: { backgroundColor: '#8BC34A', color: '#fff' },
  d: { backgroundColor: '#007bff', color: '#fff' },
  c: { backgroundColor: '#6c757d', color: '#fff' },
  x: { backgroundColor: '#dc3545', color: '#fff' },
  header: {
    position: 'absolute',
    top: 75,
    right: 10,
    borderRadius: 4,
  },
  action: {
    position: 'absolute',
    zIndex: 10,
    bottom: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    backgroundColor: '#fff',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
    },
  },
  actionContent: {
    display: 'flex',
    padding: 15,
    justifyContent: 'flex-end',
    width: '100%',
    '& button': {
      marginLeft: 10,
    },
  },
  content: {
    display: 'grid',
    rowGap: 10,
  },
}));

OrderPreview.propTypes = {
  onExited: PropTypes.func.isRequired,
};

export default function OrderPreview({ onExited }) {
  const classes = useStyles();
  const { selectedOrder, handleOrderStatus, setDialogDeliverer } = useOrders();
  const restaurant = useSelector(state => state.restaurant);

  function handleEditClick() {
    if (!selectedOrder) return;
    history.push(`/orders/${selectedOrder.encrypted_id}`);
  }

  function handleStatusClick(handleCloseDialog) {
    if (!restaurant) return;
    if (selectedOrder.status === 'a') {
      if (restaurant.configs.use_deliverer && selectedOrder.shipment.shipment_method === 'delivery')
        setDialogDeliverer(true);
      else handleOrderStatus();

      handleCloseDialog();
      return;
    }

    handleOrderStatus();
    handleCloseDialog();
  }

  return (
    <CustomDialog title={`Pedido ${selectedOrder.formattedId}`} handleModalState={onExited} hasAction maxWidth="sm">
      {selectedOrder && (
        <DialogConsumer>
          {({ handleCloseDialog }) => (
            <>
              <div className={classes.content}>
                <OrderResumeCustomer customer={selectedOrder.customer} />
                <OrderResumeShipment order={selectedOrder} />
                <OrderResumePaymentMethod order={selectedOrder} />
                <OrderPreviewProductList products={selectedOrder.products} />
                <OrderPreviewTotal
                  subtotal={selectedOrder.formattedSubtotal}
                  discount={selectedOrder.formattedDiscount}
                  total={selectedOrder.formattedTotal}
                  coupon={selectedOrder.coupon && selectedOrder.coupon.name}
                  tax={selectedOrder.formattedTax}
                />
              </div>

              <div className={classes.action}>
                <div className={classes.actionContent}>
                  {selectedOrder.status !== 'c' && selectedOrder.status !== 'x' && selectedOrder.status !== 'p' && (
                    <Button
                      color="primary"
                      size="large"
                      variant="contained"
                      onClick={() => handleStatusClick(handleCloseDialog)}
                    >
                      {selectedOrder.status === 'o'
                        ? 'Aprovar'
                        : selectedOrder.status === 'a'
                        ? 'Entregar'
                        : selectedOrder.status === 'd' && 'Finalizar'}
                    </Button>
                  )}
                  <Button color="primary" size="large" variant="text" onClick={handleEditClick}>
                    {selectedOrder.status === 'o' || selectedOrder.status === 'a' ? 'Editar' : 'Visualizar'}
                  </Button>
                </div>
              </div>
            </>
          )}
        </DialogConsumer>
      )}
    </CustomDialog>
  );
}
