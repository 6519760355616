import React, { useState, FormEvent } from 'react';
import { Typography, Button, TextField } from '@material-ui/core';
import DialogInput, { DialogInputConsumer } from 'components/dialog/DialogInput';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'store/redux/selector';
import { useOrder } from 'pages/board-management/registration/registration/hooks/useOrder';

const useStyles = makeStyles(theme => ({
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    textAlign: 'center',
  },
  total: {
    fontWeight: 500,
  },
  btnConfirm: {
    marginTop: 30,
    alignSelf: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    flex: 1,
  },
}));

PaymentChange.propTypes = {
  onExited: PropTypes.func.isRequired,
};

interface PaymentChangeProps {
  onExited(): void;
}

export default function PaymentChange({ onExited }: PaymentChangeProps) {
  const classes = useStyles();
  const [hasChange, setHasChange] = useState(false);
  const [paymentChange, setPaymentChange] = useState<number>(0);
  const order = useSelector(state => state.order);
  const { handleSetChange } = useOrder();

  function handleChangeYes() {
    setHasChange(true);
  }

  function handleChangeNo(handleCloseDialog: () => void) {
    setHasChange(false);
    handleSetChange(0);
    handleCloseDialog();
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>, handleCloseDialog: () => void) {
    event.preventDefault();
    handleSetChange(paymentChange);
    handleCloseDialog();
  }

  return (
    <DialogInput onExited={onExited} maxWidth="sm">
      <DialogInputConsumer>
        {context => (
          <>
            {!hasChange ? (
              <div className={classes.container}>
                <Typography variant="h6">Precisa de troco?</Typography>
                <div className={classes.actions}>
                  <Button onClick={() => handleChangeNo(context.handleCloseDialog)} variant="text" color="primary">
                    Não
                  </Button>
                  <Button onClick={handleChangeYes} variant="contained" color="primary">
                    Sim
                  </Button>
                </div>
              </div>
            ) : (
              <div className={classes.container}>
                <form onSubmit={event => handleSubmit(event, context.handleCloseDialog)} className={classes.form}>
                  <Typography variant="h6">
                    Troco para quanto? O valor do pedido é
                    <span className={classes.total}>{' ' + order.formattedTotal}</span>
                  </Typography>

                  <TextField
                    label="Dinheiro (R$)"
                    placeholder="Digite o valor que você vair pagar ao entregador"
                    margin="normal"
                    fullWidth
                    autoFocus
                    value={paymentChange || ''}
                    onChange={event => setPaymentChange(parseFloat(event.target.value))}
                    type="number"
                    inputMode="decimal"
                  />
                  <div className={classes.actions}>
                    <Button onClick={() => setHasChange(false)} type="button" color="primary" variant="text">
                      voltar
                    </Button>
                    <Button type="submit" disabled={paymentChange <= order.total} color="primary" variant="contained">
                      Confirmar
                    </Button>
                  </div>
                </form>
              </div>
            )}
          </>
        )}
      </DialogInputConsumer>
    </DialogInput>
  );
}
