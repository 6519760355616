import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Order } from 'types/order';

const useStyles = makeStyles(theme => ({
  container: {},
  scheduledAt: {
    background: theme.palette.warning.light,
    padding: '6px 10px',
    borderRadius: 0,
    display: 'flex',
    alignSelf: 'flex-start',
    marginTop: 5,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface OrderResumeShipmentProps {
  order: Order;
}

function OrderResumeShipment({ order }: OrderResumeShipmentProps) {
  const classes = useStyles();

  return (
    <>
      {order?.shipment && (
        <div className={classes.container}>
          <Typography color="primary" variant="h6">
            {order.shipment.shipment_method === 'delivery' ? 'Endereço de entrega' : 'Endereço para retirada'}
          </Typography>
          <div className={classes.content}>
            <Typography>
              {order.shipment.address}, {order.shipment.number}
              {order.shipment.complement}
            </Typography>
            <Typography>{order.shipment.district}</Typography>
            <Typography color="textSecondary">
              {order.shipment.city}, {order.shipment.region}
            </Typography>
            {order.shipment.postal_code !== '00000000' && (
              <Typography color="textSecondary">{order.shipment.postal_code}</Typography>
            )}
            {order.shipment.scheduled_at && (
              <Typography className={classes.scheduledAt} variant="subtitle2">
                Agendado para {order.shipment.formattedScheduledAt}
              </Typography>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default OrderResumeShipment;
