import React, { useEffect, useMemo, useState } from 'react';
import AddressesList from './AddressesList';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomerFormAddress from 'components/customer/edit/address/new/CustomerFormAddress';
import CustomerFormEditAddress from 'components/customer/edit/address/edit/CustomerFormAddress';
import { api } from 'services/api';
import { useSelector, useDispatch } from 'react-redux';
import {
  setShipmentMethod,
  setShipmentAddress,
  addCustomerAddress,
  updateCustomerAddress,
} from 'store/redux/modules/order/actions';
import ShipmentCollectSchedule from './ShipmentCollectSchedule';
import { useMessaging } from 'hooks/messaging';
import { useOrder } from 'pages/board-management/registration/registration/hooks/useOrder';

const useStyles = makeStyles({
  noAddresses: {
    padding: 15,
  },
  button: {
    padding: 0,
  },
});

function Shipment() {
  const { handleNext, setStep } = useOrder();
  const { customer, shipment } = useSelector(state => state.order);
  const classes = useStyles();
  const [saving, setSaving] = useState(false);
  const [dialogNewCustomerAddress, setDialogNewCustomerAddress] = useState(false);
  const [dialogEditCustomerAddress, setDialogEditCustomerAddress] = useState(false);
  const [dialogCollectSchedule, setDialogSchecule] = useState(false);
  const [selectedAddressForUpdate, setSelectedAddressForUpdate] = useState(null);
  const messaging = useMessaging();
  const restaurant = useSelector(state => state.restaurant);
  const dispatch = useDispatch();

  useEffect(() => {
    setDialogNewCustomerAddress(customer.id && customer.addresses.length === 0);
  }, [customer]);

  const shipmentAddressId = useMemo(() => {
    return shipment ? shipment.customer_address_id || shipment.id : null;
  }, [shipment]);

  function selectAddress(id) {
    const address = customer.addresses.find(address => address.id === id);

    if (restaurant.configs.tax_mode === 'district')
      if (!address.area_region) {
        setDialogEditCustomerAddress(true);
        setSelectedAddressForUpdate(address);
        messaging.handleOpen('Atualize o bairro');
        return;
      }

    dispatch(setShipmentMethod('delivery'));
    dispatch(setShipmentAddress(address));
    handleNext();
  }

  function handleSubmit(data) {
    setSaving(true);
    return new Promise((resolve, reject) => {
      api
        .post('/addresses', data)
        .then(response => {
          dispatch(addCustomerAddress(response.data));
          dispatch(setShipmentAddress(response.data));
          resolve(true);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          setSaving(false);
        });
    });
  }

  function handleAddressUpdateSubmit(data) {
    setSaving(true);
    return new Promise((resolve, reject) => {
      api
        .put(`/addresses/${selectedAddressForUpdate.id}`, data)
        .then(response => {
          dispatch(setShipmentAddress(response.data));
          dispatch(updateCustomerAddress(response.data));
          resolve(true);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          setSaving(false);
        });
    });
  }

  function handleOpenDialogEditAddress(id) {
    setSelectedAddressForUpdate(customer.addresses.find(address => address.id === id));
    setDialogEditCustomerAddress(!dialogEditCustomerAddress);
  }

  function handleSetShipmentCustomerCollect() {
    dispatch(setShipmentMethod('customer_collect'));
    if (restaurant.configs.shipment_schedule) {
      setDialogSchecule(true);
      return;
    }
    handleNext();
  }

  function handleDialogClose() {
    setDialogSchecule(false);
    handleNext();
  }

  function handleSetStepCustomer() {
    setStep(1);
  }

  return (
    <>
      {dialogCollectSchedule && <ShipmentCollectSchedule onExited={handleDialogClose} />}
      {dialogNewCustomerAddress && (
        <CustomerFormAddress
          handleModalState={() => setDialogNewCustomerAddress(false)}
          customerId={customer.id}
          handleAddressSubmit={handleSubmit}
          saving={saving}
        />
      )}
      {dialogEditCustomerAddress && (
        <CustomerFormEditAddress
          handleModalState={() => setDialogEditCustomerAddress(false)}
          customerId={customer.id}
          handleAddressUpdateSubmit={handleAddressUpdateSubmit}
          saving={saving}
          selectedAddress={selectedAddressForUpdate}
        />
      )}
      {customer.id ? (
        <>
          <AddressesList
            shipmentAddressId={shipmentAddressId}
            selectAddress={selectAddress}
            addresses={customer.addresses}
            openDialogNewCustomerAddress={() => setDialogNewCustomerAddress(true)}
            handleOpenDialogCustomerEditAddress={handleOpenDialogEditAddress}
            handleSetShipmentCustomerCollect={handleSetShipmentCustomerCollect}
          />
        </>
      ) : (
        <div className={classes.noAddresses}>
          <Typography gutterBottom variant="body1">
            Você precisa selecionar o cliente
          </Typography>
          <Button color="primary" variant="text" className={classes.button} onClick={handleSetStepCustomer}>
            Ir para o cliente
          </Button>
        </div>
      )}
    </>
  );
}

export default Shipment;
