import { FC } from 'react';
import CategoryItem from './CategoryItem';
import { Category } from 'types/category';
import List from 'components/list/List';

interface CategoryListProps {
  categories: Category[];
  handleAddCategory(category: Category): void;
}

const CategoryList: FC<CategoryListProps> = ({ categories, handleAddCategory }) => {
  return (
    <List>
      {categories.map(item => (
        <CategoryItem handleAddCategory={handleAddCategory} category={item} key={item.id} />
      ))}
    </List>
  );
};

export default CategoryList;
