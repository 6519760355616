import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { OrderProductAdditional } from 'types/product';

type OrderPreviewProductAdditionalProps = {
  additional: OrderProductAdditional[];
};

const useStyles = makeStyles({
  container: {
    flexDirection: 'row',
    flex: 1,
  },
  additionalName: {
    color: '#4CAF50',
    marginRight: 7,
  },
});

const OrderPreviewProductAdditional: React.FC<OrderPreviewProductAdditionalProps> = ({ additional }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {additional.map(_additional => (
        <Typography key={_additional.id} className={classes.additionalName} variant="body2" display="inline">
          c/ {_additional.amount}x {_additional.name}
        </Typography>
      ))}
    </div>
  );
};

export default OrderPreviewProductAdditional;
