import { useState, Dispatch, SetStateAction } from 'react';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { Button, Typography, Card, CardContent, Menu, MenuItem, IconButton } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import MoreVertIcon from '@material-ui/icons/MoreVertTwoTone';
import { Customer } from 'types/customer';

const useStyles = makeStyles(theme => ({
  button: {
    padding: 0,
  },
  typography: {
    display: 'flex',
    alignItems: 'center',
  },
  card: {
    marginBottom: 10,
    marginTop: 10,
    boxShadow: 'none',
    border: `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
    position: 'relative',
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
  },
  iconPerson: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
  },
  avatar: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.light}`,
    width: 50,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonRemove: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  iconButton: {
    display: 'none',
    position: 'absolute',
    top: 0,
    right: 0,
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  customerContent: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '75%',
    },
  },
  container: {
    maxWidth: '600px',
    width: '100%',
  },
}));

interface SelectedCustomerProps {
  customer: Customer;
  handleSetCustomer(customer: Customer | null): Promise<void>;
  setDialogCustomerSearch: Dispatch<SetStateAction<boolean>>;
}

function CustomerSelected({ customer, handleSetCustomer, setDialogCustomerSearch }: SelectedCustomerProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  function handleMenuItemClick() {
    handleSetCustomer(null);
    setDialogCustomerSearch(true);
    setAnchorEl(null);
  }

  function handleButtonClick() {
    handleSetCustomer(null);
    setDialogCustomerSearch(true);
  }

  return (
    <>
      <Menu open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        <MenuItem onClick={handleMenuItemClick}>Remover</MenuItem>
      </Menu>

      <div className={classes.container}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <div className={classes.iconPerson}>
              <div className={classes.avatar}>
                <PersonIcon color="primary" />
              </div>
            </div>
            <div className={classes.customerContent}>
              <Typography className={classes.typography} variant="h6">
                {customer.name}
              </Typography>
              <Typography className={classes.typography} color="textSecondary" variant="body1">
                {customer.phone}
              </Typography>
            </div>
          </CardContent>

          <Button
            className={classes.buttonRemove}
            size="small"
            variant="text"
            color="primary"
            onClick={handleButtonClick}
          >
            Remover
          </Button>
          <IconButton className={classes.iconButton} onClick={event => setAnchorEl(event.currentTarget)}>
            <MoreVertIcon />
          </IconButton>
        </Card>
      </div>
    </>
  );
}

export default CustomerSelected;
