import React from 'react';
import { Tooltip, IconButton, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import history from 'services/history';
import { FilterList, Schedule } from '@material-ui/icons';

type OrdersActionsProps = {
  openSearch(): void;
  openDeliveryTime(): void;
};

const styles = makeStyles(theme => ({
  button: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
}));

const OrdersActions: React.FC<OrdersActionsProps> = ({ openSearch, openDeliveryTime }) => {
  const classes = styles();

  return (
    <>
      <Tooltip title="Tempo de entrega" className={classes.button}>
        <IconButton color="inherit" onClick={openDeliveryTime}>
          <Schedule />
        </IconButton>
      </Tooltip>
      <Tooltip title="Buscar pedidos" className={classes.button}>
        <IconButton color="inherit" onClick={openSearch}>
          <FilterList />
        </IconButton>
      </Tooltip>
      <Tooltip title="Novo pedido" className={classes.button} onClick={() => history.push('orders/new')}>
        <IconButton color="inherit">
          <AddIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default OrdersActions;
