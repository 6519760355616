import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { ListItem, Typography, makeStyles, alpha, useTheme } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import { PaymentMethod } from 'types/paymentMethod';
import { useSelector } from 'store/redux/selector';
import { useOrder } from 'pages/board-management/registration/registration/hooks/useOrder';
import { MdPix } from 'react-icons/md';

const useStyles = makeStyles(theme => ({
  listItem: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 10,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#fff',
    border: '1px solid #f5f5f5',
    minHeight: 100,
  },
  selected: {
    border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    position: 'relative',
    '&:focus': {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.25),
    },
  },
  checkIcon: {
    position: 'absolute',
    right: 10,
    backgroundColor: '#fff',
    borderRadius: '50%',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
  },
  icon: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.light}`,
    width: 50,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  method: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface PaymentListItemProps {
  paymentMethod: PaymentMethod;
  setDialogChange: Dispatch<SetStateAction<boolean>>;
}

function PaymentListItem({ paymentMethod, setDialogChange }: PaymentListItemProps) {
  const classes = useStyles();
  const { handleNext, handleSetChange, handleSetPaymentMethod } = useOrder();
  const order = useSelector(state => state.order);
  const theme = useTheme();

  const paymentMethodId = useMemo(() => {
    return order.payment_method ? order.payment_method.id : null;
  }, [order]);

  function handleClick(paymentMethod: PaymentMethod) {
    handleSetPaymentMethod(paymentMethod);

    if (paymentMethod.kind === 'money') {
      setDialogChange(true);
      return;
    }

    handleSetChange(0);

    handleNext();
  }

  return (
    <>
      <ListItem
        onClick={() => handleClick(paymentMethod)}
        button
        className={paymentMethod.id === paymentMethodId ? `${classes.listItem} ${classes.selected}` : classes.listItem}
        key={paymentMethod.id}
      >
        <div className={classes.iconContainer}>
          <div className={classes.icon}>
            {paymentMethod.kind === 'money' && <AttachMoneyIcon color="primary" />}
            {paymentMethod.kind === 'card' && <CreditCardIcon color="primary" />}
            {paymentMethod.kind === 'picpay' && <SmartphoneIcon color="primary" />}
            {paymentMethod.kind === 'pix' && <SmartphoneIcon color="primary" />}
            {paymentMethod.kind === 'other' && paymentMethod.method === 'PIX' && (
              <MdPix size={20} color={theme.palette.primary.main} />
            )}
          </div>
        </div>

        <div className={classes.method}>
          <Typography variant="h6">{paymentMethod.method}</Typography>
          <Typography variant="body2">{paymentMethod.mode === 'offline' ? 'Offline' : 'Online'}</Typography>
          {order.change > 0 && paymentMethod.kind === 'money' && (
            <Typography color="textSecondary">Troco para {order.formattedChange}</Typography>
          )}
        </div>

        {paymentMethod.id === paymentMethodId && <CheckCircleIcon color="primary" className={classes.checkIcon} />}
      </ListItem>
    </>
  );
}

export default PaymentListItem;
