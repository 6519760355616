import React from 'react';
import { Typography, ListItem, List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import OrderPreviewProductListComplements from './OrderPreviewProductListComplements';
import OrderPreviewProductIngredients from './OrderPreviewProductIngredients';
import OrderPreviewProductAdditional from './OrderPreviewProductAdditional';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 10,
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 3,
    borderBottom: '1px solid #eee',
    alignItems: 'flex-start',
  },
  additional: {
    color: '#4CAF50',
    marginRight: 6,
  },
  ingredients: {
    color: '#c53328',
    marginRight: 6,
  },
  finalPrice: {
    fontSize: 18,
  },
  data: {
    width: '80%',
    marginRight: 5,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

OrderPreviewProductList.propTypes = {
  products: PropTypes.array.isRequired,
};

export default function OrderPreviewProductList({ products }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="h6" color="primary">
        Produtos
      </Typography>
      <List className={classes.list} disablePadding>
        {products.map(product => (
          <ListItem key={product.id} className={classes.listItem}>
            <div className={classes.data}>
              <Typography>
                {product.amount}x {product.name}
              </Typography>
              {product.annotation && (
                <Typography variant="body2" color="textSecondary">
                  Obs: {product.annotation}
                </Typography>
              )}
              {product.promotion && (
                <Typography variant="caption" color="textSecondary">
                  Promoção {product.promotion.name}
                </Typography>
              )}
              {product.category.has_complement && (
                <OrderPreviewProductListComplements categories={product.complement_categories} />
              )}
              {product.additional.length > 0 && <OrderPreviewProductAdditional additional={product.additional} />}
              {product.ingredients.length > 0 && <OrderPreviewProductIngredients ingredients={product.ingredients} />}
            </div>
            <Typography className={classes.finalPrice}>{product.formattedFinalPrice}</Typography>
          </ListItem>
        ))}
      </List>
    </div>
  );
}
