import React, { FormEvent, useState } from 'react';
import { makeStyles, Typography, Button, CircularProgress } from '@material-ui/core';
import ForgotForm from './ForgotForm';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    backgroundColor: theme.palette.primary.light,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      backgroundColor: '#fff',
    },
  },
  content: {
    display: 'flex',
    padding: 30,
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: theme.shape.borderRadius,
    minWidth: 400,
    margin: 20,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: 300,
      padding: 10,
    },
  },
  header: {
    padding: '40px 0',
  },
  actions: {
    display: 'flex',
    padding: '50px 0',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    '& > .login': {
      marginTop: 30,
    },
  },
  loading: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
}));

export type ForgotValidation = {
  email?: string;
};

const Forgot: React.FC = () => {
  const classes = useStyles();
  const [validation, setValidation] = useState<ForgotValidation>({});
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const messaging = useMessaging();

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    const schema = yup.object().shape({
      email: yup.string().email('Informe um e-mail válido').required('Informe o email'),
    });

    schema
      .validate({ email })
      .then(() => {
        handleSubmit();
        setValidation({});
      })
      .catch((err: yup.ValidationError) => {
        setValidation({
          [err.path as string]: err.message,
        });
      });
  }

  function handleSubmit() {
    setLoading(true);

    api
      .post('/password/email', { email })
      .then(() => {
        messaging.handleOpen('Uma mensagem foi enviada para a conta de e-mail informada');
        setEmail('');
      })
      .catch(err => {
        if (err.response) messaging.handleOpen(err.response.data.error);
        else messaging.handleOpen('Aconteceu um erro. Não foi possível enviar e-mail');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <form onSubmit={handleValidation}>
          {loading && (
            <div className={classes.loading}>
              <CircularProgress color="primary" />
            </div>
          )}
          <div className={classes.header}>
            <Typography align="center" variant="h6">
              Redefinir senha
            </Typography>
            <Typography align="center" color="textSecondary" variant="body2">
              Instruções serão enviadas ao e-mail informado
            </Typography>
          </div>
          <ForgotForm validation={validation} email={email} setEmail={setEmail} />
          <div className={classes.actions}>
            <Button type="submit" color="primary" variant="contained" disableElevation fullWidth disabled={loading}>
              Enviar
            </Button>
            <div className="login">
              <Link to="/login">Voltar ao login</Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Forgot;
