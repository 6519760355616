import { alpha, makeStyles, Typography } from '@material-ui/core';
import { moneyFormat } from 'helpers/NumberFormat';
import React, { useMemo } from 'react';
import { useBoardControl } from './hooks/useBoardControl';

const styles = makeStyles(theme => ({
  container: {
    padding: '10px 15px',
    display: 'flex',
    backgroundColor: alpha(theme.palette.warning.main, 0.2),
    textAlign: 'right',
    flexDirection: 'column',
  },
  total: {
    fontSize: 20,
    // fontWeight: 'bold',
  },
}));

const BoardSelectedTotal: React.FC = () => {
  const classes = styles();

  const { selectedProducts } = useBoardControl();

  const total = useMemo(
    () => selectedProducts.reduce((previous, value) => previous + value.final_price, 0),
    [selectedProducts]
  );

  const formattedTotal = useMemo(() => moneyFormat(total), [total]);

  return total ? (
    <div className={classes.container}>
      <Typography color="textSecondary">selecionado</Typography>
      <Typography className={classes.total}>{formattedTotal}</Typography>
    </div>
  ) : (
    <></>
  );
};

export default BoardSelectedTotal;
