import { Avatar, Button, IconButton, Typography, makeStyles } from '@material-ui/core';
import DialogInput, { DialogInputConsumer } from 'components/dialog/DialogInput';
import React, { useMemo } from 'react';
import { useBoardControl } from '../hooks/useBoardControl';
import { addBoardProducts, removeBoardProduct } from '../reducer/actions';

const styles = makeStyles({
  container: {
    display: 'flex',
    minHeight: 350,
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-around',
    '& > .title': {
      textAlign: 'center',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  amountButtonContent: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
});

interface BoardControlSpliProductProps {
  onExited(): void;
}

const BoardControlSplitProduct: React.FC<BoardControlSpliProductProps> = ({ onExited }) => {
  const classes = styles();
  const { selectedProduct, dispatch } = useBoardControl();

  const numbers = useMemo(() => {
    if (!selectedProduct) {
      return [];
    }

    return Array.from(Array(selectedProduct.amount + 1).keys()).filter(number => {
      return selectedProduct.amount % number === 0;
    });
  }, [selectedProduct]);

  function handleConfirm(number: number, closeDialog: () => void) {
    if (!selectedProduct) {
      closeDialog();
      return;
    }

    dispatch(removeBoardProduct(selectedProduct.id));

    const amount = selectedProduct.amount / number;
    const final_price = selectedProduct.final_price / number;

    Array.from(Array(number)).forEach(() => dispatch(addBoardProducts([{ ...selectedProduct, final_price, amount }])));

    closeDialog();
  }

  return (
    <DialogInput maxWidth="sm" onExited={onExited}>
      <DialogInputConsumer>
        {context => (
          <div className={classes.container}>
            <Typography className="title" variant="h6">
              Escolha em quantas vezes dividir o produto
            </Typography>

            <div className={classes.amountButtonContent}>
              {numbers.map(number => (
                <IconButton onClick={() => handleConfirm(number, context.handleCloseDialog)} key={number}>
                  <Avatar color="primary">{number}</Avatar>
                </IconButton>
              ))}
            </div>

            <div className={classes.actions}>
              <Button onClick={context.handleCloseDialog} variant="text" color="primary">
                Cancelar
              </Button>
              <Button variant="contained" color="primary">
                Confirmar
              </Button>
            </div>
          </div>
        )}
      </DialogInputConsumer>
    </DialogInput>
  );
};

export default BoardControlSplitProduct;
