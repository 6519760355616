import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import AuthProvider from 'hooks/auth';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { theme } from 'theme';
import { store } from 'store/redux/index';
import { Provider as ReduxProvider } from 'react-redux';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './index.css';
import { BrowserRouter } from 'routes/BrowserRouter';
import history from 'services/history';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BrowserRouter history={history}>
    <MuiThemeProvider theme={theme}>
      <ReduxProvider store={store}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </ReduxProvider>
    </MuiThemeProvider>
  </BrowserRouter>
);

serviceWorkerRegistration.register();
