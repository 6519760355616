import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NotFound from 'components/notfound/NotFound';
import Login from 'pages/login/Login';
import Forgot from 'pages/forgot/Forgot';
import Home from 'components/home/Home';
import NewOrder from 'pages/orders/registration/new/Order';
import EditOrder from 'pages/orders/registration/edit/Order';
import Account from 'components/account/Account';
import PrivateNotFound from 'components/notfound/PrivateNotFound';
import DeliveryOrders from 'pages/orders/Orders';
import Tables from 'pages/boards/Boards';
import BoardNew from 'pages/boards/registration/new/BoardNew';
import BoardEdit from 'pages/boards/registration/edit/BoardEdit';
import BoardManagement from 'pages/board-management/BoardManagement';
import PublicRoute from './PublicRoute';
import ProtectedRoute from './ProtectedRoute';
import BoardManagementOrder from 'pages/board-management/registration/registration/new/Order';
import BoardControl from 'pages/board-management/registration/board/BoardControl';
import BoardMovements from 'pages/board-movements/BoardMovements';
import BoardOrders from 'pages/board-orders/BoardOrders';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/login" element={<PublicRoute element={<Login />} />} />
      <Route path="/forgot" element={<PublicRoute element={<Forgot />} />} />
      <Route path="/" element={<ProtectedRoute element={<Home />} />} />
      <Route path="/board-orders" element={<ProtectedRoute element={<BoardOrders />} />} />
      <Route path="/delivery-orders" element={<ProtectedRoute element={<DeliveryOrders />} />} />
      <Route path="/orders/new" element={<ProtectedRoute element={<NewOrder />} />} />
      <Route path="/orders/:id" element={<ProtectedRoute element={<EditOrder />} />} />
      <Route path="/account" element={<ProtectedRoute element={<Account />} />} />
      <Route path="/not-found" element={<ProtectedRoute element={<PrivateNotFound />} />} />
      <Route path="/boards" element={<ProtectedRoute element={<Tables />} />} />
      <Route path="/board" element={<ProtectedRoute element={<BoardNew />} />} />
      <Route path="/board/:id" element={<ProtectedRoute element={<BoardEdit />} />} />
      <Route path="/board-management" element={<ProtectedRoute element={<BoardManagement />} />} />
      <Route path="/board-management/orders" element={<ProtectedRoute element={<BoardManagementOrder />} />} />
      <Route path="/board-management/movements/:id" element={<ProtectedRoute element={<BoardControl />} />} />
      <Route path="/board-movement/movements" element={<ProtectedRoute element={<BoardMovements />} />} />
    </Routes>
  );
};

export default AppRoutes;
