import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import PropTypes from 'prop-types';

CustomerFormAction.propTypes = {
  saving: PropTypes.bool.isRequired,
};

function CustomerFormAction({ saving }) {
  return (
    <Tooltip title="Salvar">
      <span>
        <IconButton type="submit" disabled={saving} color="inherit">
          <DoneIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
}

export default CustomerFormAction;
