import React, { Fragment } from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';

function OrderAction() {
  return (
    <Fragment>
      <Tooltip title="Atualizar">
        <IconButton
          onClick={() => {
            window.print();
          }}
          color="inherit"
        >
          <PrintIcon />
        </IconButton>
      </Tooltip>
    </Fragment>
  );
}

export default OrderAction;
