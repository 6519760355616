import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { Deliverer } from 'types/deliverer';
import { Order } from 'types/order';
import { OrderStatusOptions } from 'types/orderStatus';
import { OrderList } from '../types/orderList';

export type OrdersContextValue = {
  deliverers: Deliverer[];
  selectedOrder: Order | null;
  orders: Order[];
  setSelectedOrder: Dispatch<SetStateAction<OrderList | null>>;
  setDialogPrintOrder: Dispatch<SetStateAction<boolean>>;
  setDialogPrintShipment: Dispatch<SetStateAction<boolean>>;
  setDialogDeliverer: Dispatch<SetStateAction<boolean>>;
  setDialogOrderPreview: Dispatch<SetStateAction<boolean>>;
  setDialogTrack: Dispatch<SetStateAction<boolean>>;
  setDialogDeliveryTime: Dispatch<SetStateAction<boolean>>;
  setDialogPrintOnlyShipment: Dispatch<SetStateAction<boolean>>;
  handleOrderStatus(status?: OrderStatusOptions, delivererId?: number): void;
  handleCancel(): void;
  sendToPrintShipment(): void;
  sendToPrintOrder(): void;
  handleCreateReceipt(): void;
};

const OrdersContext = createContext<OrdersContextValue>({} as OrdersContextValue);
export const OrdersContextProvider = OrdersContext.Provider;

export function useOrders(): OrdersContextValue {
  const context = useContext(OrdersContext);
  return context;
}
