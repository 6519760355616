import { useEffect, useState } from 'react';
import { api } from 'services/api';
import { BoardMovementPayment } from 'types/boardMovementPayment';
import { setBoardPayments } from '../reducer/actions';
import { BoardControlActions } from '../reducer/types';

export type UseFetchBoardMovementPayment = [boolean];

export function useFetchBoardMovementPayment(
  dispatch: (action: BoardControlActions) => void,
  movementId?: string
): UseFetchBoardMovementPayment {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!movementId) {
      return;
    }

    api
      .get<BoardMovementPayment[]>(`/boardMovements/${movementId}/payments`)
      .then(response => {
        dispatch(setBoardPayments(response.data));
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [movementId, dispatch]);

  return [loading];
}
