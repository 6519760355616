import React, { useState, Dispatch, SetStateAction } from 'react';
import { TextField } from '@material-ui/core';
import CustomerFormAction from './CustomerFormAction';
import { api } from 'services/api';
import PhoneInput from 'components/masked-input/PhoneInput';
import { useMessaging } from 'hooks/messaging';
import CustomDialogForm from 'components/dialog/CustomDialogForm';
import InsideSaving from 'components/loading/InsideSaving';
import { Customer } from 'types/customer';
import { useOrder } from 'pages/board-management/registration/registration/hooks/useOrder';

interface CustomerFormProps {
  setDialogCustomerForm: Dispatch<SetStateAction<boolean>>;
  handleChangeCustomer(customer: Customer | null): void;
  setDialogCustomerSearch: Dispatch<SetStateAction<boolean>>;
  textSearch: string;
}

const CustomerForm: React.FC<CustomerFormProps> = ({
  setDialogCustomerForm,
  handleChangeCustomer,
  setDialogCustomerSearch,
  textSearch,
}) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState(textSearch);
  const messaging = useMessaging();
  const { handleNext } = useOrder();
  const [saving, setSaving] = useState(false);

  async function handleSubmit() {
    setDialogCustomerSearch(false);

    try {
      setSaving(true);
      const response = await api.post('/customers', { name, phone });
      handleChangeCustomer(response.data.data);
      handleNext();
    } catch {
      messaging.handleOpen('Erro salvar o cliente');
    } finally {
      setSaving(false);
    }
  }

  return (
    <CustomDialogForm
      async
      handleSubmit={handleSubmit}
      title="Novo cliente"
      handleModalState={setDialogCustomerForm}
      componentActions={<CustomerFormAction />}
      hideBackdrop
      displayBottomActions={false}
      maxWidth="sm"
    >
      {saving && <InsideSaving />}

      <TextField
        required
        label="Nome"
        placeholder="Digite o nome do cliente"
        margin="normal"
        fullWidth
        autoFocus
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <TextField
        required
        label="Telefone"
        margin="normal"
        placeholder="Digite o telefone do cliente"
        fullWidth
        value={phone}
        onChange={e => setPhone(e.target.value)}
        InputProps={{
          inputComponent: PhoneInput as any,
        }}
      />
    </CustomDialogForm>
  );
};

export default CustomerForm;
