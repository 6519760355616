import React, { Fragment } from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

function CustomerSearchAction({ openDialogCustomerForm }) {
  return (
    <Fragment>
      <Tooltip title="Novo cliente">
        <IconButton onClick={openDialogCustomerForm} color="inherit">
          <AddIcon />
        </IconButton>
      </Tooltip>
    </Fragment>
  );
}

export default CustomerSearchAction;
