import React, { useMemo, useState, createContext, useEffect } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import OrderAction from './OrderAction';
import {
  addProducts,
  removeProduct,
  setPaymentMethod,
  setAnnotation,
  updateComplement,
  updateProduct,
  setChange,
  setOrderInitialState,
} from 'store/redux/modules/order/actions';
import PageHeader from 'components/page-header/PageHeader';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { steps } from '../../steps';
import Customer from '../steps/customer/Customer';
import Loading from 'components/loading/Loading';
import Category from '../steps/category/Category';
import Products from '../steps/products/Products';
import Shipment from '../steps/shipment/Shipment';
import OrderStatus from '../status/OrderStatus';
import OrderConfirmation from '../steps/confirmation/OrderConfirmation';
import OrderResume from '../resume/OrderResume';
import { api } from 'services/api';
import Payment from '../steps/payment/Payment';
import history from 'services/history';
import OrderButtons from '../OrderButtons';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMessaging } from 'hooks/messaging';
import { useSelector } from 'store/redux/selector';
import { Category as CategoryType } from 'types/category';
import InsideLoading from 'components/loading/InsideLoading';
import { OrderProduct } from 'types/order';
import SearchProducts from 'pages/board-management/registration/registration/steps/products/SearchProducts';
import {
  OrderContextProvider,
  OrderContextValue,
} from 'pages/board-management/registration/registration/hooks/useOrder';
import { useFetchPaymentMethods } from 'pages/orders/hook/useFetchPaymentMethods';
import { useFetchCategories } from 'pages/orders/hook/useFetchCategories';
import { useFetchOrderAndDistances } from 'pages/orders/hook/useFetchOrderAndDistances';
import { getOrderDataToSubmit } from '../new/getOrderDataToSubmit';

const useStyles = makeStyles(theme => ({
  step: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    width: 25,
    height: 25,
    borderRadius: '50%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    marginRight: 10,
    border: `2px solid ${theme.palette.primary.dark}`,
  },
  currentStep: {
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
  },
  steps: {
    padding: '10px 0',
    display: 'flex',
    alignItems: 'center',
  },
  contentStep: {
    display: 'flex',
    padding: '15px 0',
    borderRadius: 4,
    flexDirection: 'column',
    marginTop: 10,
    width: 'calc(100% - 400px)',
    flex: 1,
    marginBottom: 50,
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 350px)',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '30px 0px',
      border: 'none',
      marginTop: 0,
      width: '100%',
    },
  },
  orderStatus: {
    [theme.breakpoints.down('md')]: {
      width: 350,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    position: 'fixed',
    right: 0,
    top: 0,
    bottom: 0,
    width: 400,
    backgroundColor: '#fff',
    padding: 15,
    marginTop: 60,
    borderLeft: '1px solid #eee',
    overflow: 'scroll',
  },
  line: {
    border: `1px solid #ccc`,
    display: 'block',
  },
  lineContainer: {
    display: 'inline-block',
    width: 40,
  },
  status: {
    padding: '2px 10px',
    borderRadius: theme.shape.borderRadius,
    margin: '0 0 6px',
    width: 190,
    display: 'flex',
    justifyContent: 'center',
  },
  p: { backgroundColor: '#17a2b8', color: '#fff' },
  o: { backgroundColor: '#ffc107' },
  a: { backgroundColor: '#8BC34A', color: '#fff' },
  d: { backgroundColor: '#007bff', color: '#fff' },
  c: { backgroundColor: '#6c757d', color: '#fff' },
  x: { backgroundColor: '#dc3545', color: '#fff' },
}));

export const OrderContext = createContext({
  handleNext: () => {},
  handlePrior: () => {},
  order: null,
  handleUpdateAmount: () => {},
  handleRemoveProduct: () => {},
  handleAddProduct: () => {},
  handleUpdateProduct: () => {},
});

const Order: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const { handleOpen } = useMessaging();
  const order = useSelector(state => state.order);
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [dialogResumeOrder, setDialogResumeOrder] = useState(false);
  const [categories, , loadingCategories] = useFetchCategories();
  const [paymentMethods] = useFetchPaymentMethods();
  const [loading] = useFetchOrderAndDistances(id);
  const [step, setStep] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState<CategoryType | null>(null);
  const [showSearchDialog, setShowSearchDialog] = useState(false);

  const orderContextValue: OrderContextValue = {
    handleNext,
    handlePrior,
    handleAddProduct,
    handleUpdateProduct,
    handleRemoveProduct,
    handleUpdateComplements,
    handleSetChange,
    handleAddCategory,
    handleSetAnnotation,
    handleSetPaymentMethod,
    loadingCategories,
    categories,
    paymentMethods,
    step,
    selectedCategory,
    setShowSearchDialog,
    handleSubmit,
    saving,
    setStep,
  };

  useEffect(() => {
    return () => {
      dispatch(setOrderInitialState());
    };
  }, [dispatch]);

  function handleNext() {
    if (step === 3) {
      if (!checkIfOrderHasProducts()) {
        handleOpen('Você precisa adicionar produtos ao pedidos');
        return;
      }
    }

    if (step === 2) {
      if (order.products.length > 0) {
        setStep(4);
        return;
      }

      if (categories.length > 0) {
        setStep(4);
      }
    }

    setStep(step + 1);
  }

  function handlePrior() {
    if (step === 1) {
      history.push('/delivery-orders');
    }

    if (step === 4) {
      setStep(2);
      return;
    }

    if (step === 3) {
      setSelectedCategory(null);
    }

    setStep(step - 1);
  }

  function handleSetStep(step: number) {
    if (step === 2) setSelectedCategory(null);
    setStep(step);
  }

  function handleAddCategory(category: CategoryType) {
    setSelectedCategory(category);

    if (category) {
      setStep(3);
    }
  }

  function handleAddProduct(product: OrderProduct, amount = 1) {
    dispatch(addProducts(product, amount));
  }

  function checkIfOrderHasProducts() {
    let hasProducts = false;
    hasProducts = order.products.length > 0;

    return hasProducts;
  }

  function handleRemoveProduct(uid) {
    dispatch(removeProduct(uid));
  }

  function handleSetAnnotation(annotation) {
    dispatch(setAnnotation(annotation));
  }

  function handleSetPaymentMethod(paymentMethod) {
    dispatch(setPaymentMethod(paymentMethod));
  }

  function handleUpdateComplements(product: OrderProduct, amount: number) {
    dispatch(updateComplement(product, amount));
  }

  function handleUpdateProduct(product, amount) {
    dispatch(updateProduct(product, amount));
  }

  function handleSetChange(value) {
    dispatch(setChange(value));
  }

  function handleSubmit() {
    setSaving(true);

    const data = getOrderDataToSubmit(order);

    api
      .put(`/orders/${id}`, data)
      .then(response => {
        if (response.status === 200) handleOpen('Salvo');
        history.push('/delivery-orders');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen('Não foi possível salvar o pedido');
        setSaving(false);
      });
  }

  const currentStep = useMemo(() => {
    return steps.find(item => item.order === step);
  }, [step]);

  return (
    <OrderContextProvider value={orderContextValue}>
      {saving && <Loading />}

      <CustomAppbar
        title="Alterar pedido"
        ActionComponents={<OrderAction setDialogResumeOrder={() => setDialogResumeOrder(!dialogResumeOrder)} />}
      />

      {showSearchDialog && <SearchProducts onExited={() => setShowSearchDialog(false)} />}

      {loading ? (
        <InsideLoading />
      ) : (
        <>
          <div className={`${classes.status} ${classes[order.status]}`}>
            <Typography>{order.statusText}</Typography>
          </div>

          <PageHeader
            title={`Pedido #${order.formattedId}`}
            description={`Emitido em ${order.formattedCreatedAt}`}
            backAction={handlePrior}
          />

          <div className={classes.currentStep}>
            <span className={classes.step}>{currentStep?.order}</span>
            <Typography variant={'body1'}>{currentStep?.description}</Typography>
          </div>

          <div className={classes.contentStep}>
            {dialogResumeOrder && <OrderResume onExited={() => setDialogResumeOrder(!dialogResumeOrder)} />}
            {step === 1 ? (
              <Customer />
            ) : step === 2 ? (
              <Category />
            ) : step === 3 ? (
              <Products />
            ) : step === 4 ? (
              <Shipment />
            ) : step === 5 ? (
              <Payment />
            ) : (
              step === 6 && <OrderConfirmation handleSubmit={handleSubmit} saving={saving} />
            )}

            <OrderButtons />
          </div>

          <div className={classes.orderStatus}>
            <OrderStatus order={order} handleSetStep={handleSetStep} />
          </div>
        </>
      )}
    </OrderContextProvider>
  );
};

export default Order;
