import { makeStyles } from '@material-ui/core/styles';
import { ListItem, Typography } from '@material-ui/core';
import { Category } from 'types/category';
import { FC } from 'react';

const useStyles = makeStyles(theme => ({
  listItem: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: '#fff',
    border: '1px solid #f5f5f5',
    borderRadius: theme.shape.borderRadius,
    padding: 15,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  categoryIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
  },
  avatar: {
    borderRadius: '50%',
    border: `2px solid #fff`,
    width: 50,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#eee',
  },
}));

interface CategoryItemProps {
  category: Category;
  handleAddCategory(category: Category): void;
}

const CategoryItem: FC<CategoryItemProps> = ({ category, handleAddCategory }) => {
  const classes = useStyles();

  return (
    <ListItem
      onClick={() => handleAddCategory(category)}
      key={category.name + category.description + category.id}
      className={classes.listItem}
      button
    >
      <div className={classes.content}>
        <div className={classes.categoryIcon}>
          <img
            className={classes.avatar}
            src={category.image ? category.image.imageUrl : undefined}
            alt={category.name}
          />
        </div>
        <div>
          <Typography variant={'h6'}>{category.name}</Typography>
        </div>
      </div>
    </ListItem>
  );
};

export default CategoryItem;
