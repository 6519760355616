import React, { useState, useEffect } from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import PostalCodeInput from 'components/masked-input/PostalCodeInput';
import CustomerFormAction from '../CustomerFormAction';
import CustomDialogForm from 'components/dialog/CustomDialogForm';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { moneyFormat } from 'helpers/NumberFormat';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import InsideSaving from 'components/loading/InsideSaving';

CustomerFormAddress.propTypes = {
  handleAddressUpdateSubmit: PropTypes.func.isRequired,
  handleModalState: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  selectedAddress: PropTypes.object.isRequired,
  customerId: PropTypes.number.isRequired,
};

function CustomerFormAddress({ handleAddressUpdateSubmit, handleModalState, selectedAddress, customerId, saving }) {
  const [postalCode] = useState(selectedAddress.postal_code);
  const [address, setAddress] = useState(selectedAddress.address);
  const [number, setNumber] = useState(selectedAddress.number);
  const [complement, setComplement] = useState(selectedAddress.complement ? selectedAddress.complement : '');
  const [areaRegionId, setAreaRegionId] = useState(null);
  const [district, setDistrict] = useState(selectedAddress.district);
  const [city] = useState(selectedAddress.city);
  const [region] = useState(selectedAddress.region);
  const messaging = useMessaging();
  const restaurant = useSelector(state => state.restaurant);
  const [loading, setLoading] = useState(false);
  const [regions, setRegions] = useState([]);
  const [validation, setValidation] = useState({});

  useEffect(() => {
    if (restaurant.configs.tax_mode === 'district') {
      setLoading(true);
      api
        .get('/areaRegions')
        .then(response => {
          setRegions(
            response.data.regions.map(r => {
              r.formattedTax = moneyFormat(r.tax);
              return r;
            })
          );
          setAreaRegionId(selectedAddress.area_region ? selectedAddress.area_region.area_region_id : null);
        })
        .catch(() => {
          messaging.handleOpen('Não foi possível carregar os bairros');
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [restaurant.configs]);

  async function handleValidation() {
    const schema = yup.object().shape({
      complement: yup.string().nullable(),
      district: yup.string().test('check_config', 'Bairro é obrigatório', value => {
        if (restaurant.configs.tax_mode !== 'district') {
          return !!value;
        } else return true;
      }),
      areaRegionId: yup.mixed().test('check_area', 'Bairro é obrigatório', value => {
        if (restaurant.configs.tax_mode === 'district') {
          return !!value;
        } else return true;
      }),
      number: yup.string().required('O número é obrigatório'),
      address: yup.string().required('O endereço é obrigatório'),
    });

    const data = {
      address,
      number,
      complement,
      district,
      region,
      city,
      areaRegionId,
      postal_code: restaurant.configs.use_postalcode ? postalCode : '00000000',
    };

    try {
      await schema.validate(data);
      await handleSubmit();
    } catch (err) {
      setValidation({
        [err.path]: err.message,
      });
      throw new Error('validation fails');
    }
  }

  async function handleSubmit() {
    const data = {
      address,
      number,
      complement,
      district,
      region,
      city,
      postal_code: postalCode,
      customer_id: customerId,
      area_region_id: areaRegionId,
    };

    try {
      await handleAddressUpdateSubmit(data);
    } catch (err) {
      if (err.response) {
        messaging.handleOpen(err.response.data.error);
      }
      throw new Error(err);
    }
  }

  function handleDistrictSelectChange(e) {
    setAreaRegionId(e.target.value);
    setDistrict(regions.find(r => r.id === e.target.value).name);
  }

  return (
    <CustomDialogForm
      title="Atualizar endereço"
      handleModalState={handleModalState}
      handleSubmit={handleValidation}
      closeOnSubmit
      async
      componentActions={<CustomerFormAction saving={loading} />}
      maxWidth="sm"
    >
      {(saving || loading) && <InsideSaving />}
      <TextField
        label="CEP"
        placeholder="Digite o CEP"
        margin="normal"
        fullWidth
        value={postalCode}
        InputProps={{
          inputComponent: PostalCodeInput,
        }}
        disabled
      />
      <TextField
        error={!!validation.address}
        helperText={!!validation.address && validation.address}
        label="Endereço"
        placeholder="Digite o endereço"
        margin="normal"
        fullWidth
        value={address}
        onChange={event => setAddress(event.target.value)}
        autoFocus
      />
      <TextField
        error={!!validation.number}
        helperText={!!validation.number && validation.number}
        label="Número"
        placeholder="Digite o número"
        margin="normal"
        fullWidth
        value={number}
        onChange={event => setNumber(event.target.value)}
      />
      {restaurant.configs.tax_mode === 'district' ? (
        <TextField
          error={!!validation.areaRegionId}
          helperText={
            validation.areaRegionId
              ? validation.areaRegionId
              : 'Se o bairro não estiver na lista, é porque não entregamos na região'
          }
          select
          label="Selecione um bairro"
          fullWidth
          value={areaRegionId || ''}
          onChange={event => handleDistrictSelectChange(event)}
          margin="normal"
        >
          {regions.map(region => (
            <MenuItem key={region.id} value={region.id}>
              {region.name} - {region.formattedTax} (taxa de entrega)
            </MenuItem>
          ))}
        </TextField>
      ) : (
        <TextField
          error={!!validation.district}
          helperText={!!validation.district && validation.district}
          label="Bairro"
          placeholder="Digite o bairro"
          margin="normal"
          fullWidth
          value={district}
          onChange={event => setDistrict(event.target.value)}
        />
      )}
      <TextField
        label="Complemento"
        placeholder="Digite o complemento"
        margin="normal"
        fullWidth
        value={complement}
        onChange={event => setComplement(event.target.value)}
      />
      <TextField
        label="Cidade"
        placeholder="Digite a cidade"
        margin="normal"
        fullWidth
        value={city}
        disabled
        required
      />
      <TextField
        label="Estado"
        placeholder="Digite o estado"
        margin="normal"
        fullWidth
        value={region}
        disabled
        required
      />
    </CustomDialogForm>
  );
}

export default CustomerFormAddress;
