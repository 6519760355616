import React, { useState } from 'react';
import List from 'components/list/List';
import OrderItemModule from './OrderItemModule';
import { OrderList } from 'pages/orders/types/orderList';
import OrderListMenu from '../OrderListMenu';

type OrderListModuleProps = {
  orders: OrderList[];
};

const OrderListModule: React.FC<OrderListModuleProps> = ({ orders }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  return (
    <>
      <OrderListMenu setAnchorEl={setAnchorEl} anchorEl={anchorEl} />
      <List>
        {orders.map(order => (
          <OrderItemModule key={order.id} order={order} setAnchorEl={el => setAnchorEl(el)} />
        ))}
      </List>
    </>
  );
};

export default OrderListModule;
