import React, { useEffect, useState, useContext } from 'react';
import { mobileCheck } from 'helpers/MobileCheck';
import { api } from 'services/api';
import MessagingProvider from 'hooks/messaging';
import { useSelector } from 'store/redux/selector';
import InvoiceError from 'components/invoice-error/InvoiceError';
import LocationProvider from 'providers/location';
import AppRoutes from 'routes/Routes';
import { useAppSocket } from 'hooks/useAppSocket';
import { Socket } from 'socket.io-client';
import { useFetchCurrentUser } from 'hooks/useFetchCurrentUser';
import { useGetNotificationToken } from 'hooks/useGetNotificationToken';

interface AppContextData {
  isOpenedMenu: boolean;
  isMobile: boolean;
  loading: boolean;
  windowWidth: number;
  wsConnected: boolean;
  socket: Socket;
  handleOpenMenu(): void;
  handleRestaurantIsOpen(): Promise<void>;
}

export const menuWidth = 266;

export const AppContext = React.createContext<AppContextData>({} as AppContextData);

const App: React.FC = () => {
  const restaurant = useSelector(state => state.restaurant);
  const user = useSelector(state => state.user);
  const [isMobile, setIsMobile] = useState(mobileCheck());
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isOpenedMenu, setIsOpenedMenu] = useState(!mobileCheck() && windowWidth > 1280);
  const [invoiceError, setInvoiceError] = useState(false);
  const [socket, wsConnected] = useAppSocket();
  const [loading] = useFetchCurrentUser();
  useGetNotificationToken();

  const context: AppContextData = {
    isOpenedMenu,
    isMobile,
    loading,
    socket,
    wsConnected,
    windowWidth,
    handleOpenMenu,
    handleRestaurantIsOpen,
  };

  useEffect(() => {
    document.body.classList.add('zoom-animation');
    window.addEventListener('resize', handleResize);
  }, [user]);

  function handleOpenMenu() {
    setIsOpenedMenu(!isOpenedMenu);
  }

  function handleResize() {
    setIsMobile(mobileCheck());
    setWindowWidth(window.innerWidth);
  }

  async function handleRestaurantIsOpen() {
    if (!restaurant) return;

    const data = {
      state: !restaurant.is_open,
    };

    try {
      await api.post('restaurant/state', data);
    } catch (err) {
      const error = err as any;

      if (error?.response?.status === 400) {
        setInvoiceError(true);
        throw new Error('Existem faturas em aberto');
      }
      throw new Error('Não foi possível alterar o status');
    }
  }

  return (
    <AppContext.Provider value={context}>
      {invoiceError && <InvoiceError onExited={() => setInvoiceError(false)} />}
      <LocationProvider>
        <MessagingProvider>
          <AppRoutes />
        </MessagingProvider>
      </LocationProvider>
    </AppContext.Provider>
  );
};

export function useApp(): AppContextData {
  return useContext(AppContext);
}

export const AppContextConsumer = AppContext.Consumer;

export default App;
