import axios from 'axios';
import { getRefreshToken } from 'hooks/auth';

export async function refreshTokenRequest(): Promise<string> {
  const api = axios.create({
    baseURL: process.env.REACT_APP_BASEURL_API,
    withCredentials: true,
    headers: {
      app: 'admin',
    },
  });

  const refresh_token = getRefreshToken();

  const response = await api.post('/auth/refresh', { refresh_token });

  return response.data.token;
}
