import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Order } from 'types/order';

const useStyles = makeStyles({
  container: {
    marginBottom: 15,
    display: 'grid',
    gridTemplateColumns: '1.3fr 0.7fr',
    gridGap: 6,
    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    '& p': {
      lineHeight: '15px',
    },
  },
  colTotal: {
    marginTop: 5,
  },
  total: {},
});

interface OrderResumeTotalProps {
  order: Order;
}

function OrderResumeTotal({ order }: OrderResumeTotalProps) {
  const classes = useStyles();

  return (
    <>
      {order.products.length > 0 && (
        <div className={classes.container}>
          <div>
            <Typography>Subtotal</Typography>
          </div>
          <div>
            <Typography>{order.formattedSubtotal}</Typography>
          </div>
          <div>
            <Typography>Desconto {order.coupon && `(${order.coupon.name})`}</Typography>
          </div>
          <div>
            <Typography>{order.formattedDiscount}</Typography>
          </div>
          <div>
            <Typography>Taxa de entrega</Typography>
          </div>
          <div>
            <Typography>{order.formattedTax}</Typography>
          </div>
          <div>
            <Typography variant="h6" className={classes.colTotal}>
              Total
            </Typography>
          </div>
          <div>
            <Typography variant="h6" className={`${classes.colTotal} ${classes.total}`}>
              {order.formattedTotal}
            </Typography>
          </div>
        </div>
      )}
    </>
  );
}

export default OrderResumeTotal;
