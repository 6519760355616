import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Ingredient } from 'types/product';

type OrderPreviewProductIngredientsProps = {
  ingredients: Ingredient[];
};

const useStyles = makeStyles({
  container: {
    flexDirection: 'row',
  },
  ingredientName: {
    color: '#c53328',
    marginRight: 7,
  },
});

const OrderPreviewProductIngredients: React.FC<OrderPreviewProductIngredientsProps> = ({ ingredients }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {ingredients.some(ingredient => !ingredient.selected) &&
        ingredients.map(
          ingredient =>
            !ingredient.selected && (
              <Typography variant="body2" key={ingredient.id} className={classes.ingredientName} display="inline">
                s/ {ingredient.name}
              </Typography>
            )
        )}
    </div>
  );
};

export default OrderPreviewProductIngredients;
