import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomerSearch from './CustomerSearch';
import CustomerForm from './CustomerForm';
import CustomerSelected from './CustomerSelected';
import { phoneFormat } from 'helpers/PhoneFormat';
import { useSelector } from 'store/redux/selector';
import { Customer as CustomerType } from 'types/customer';
import { useDispatch } from 'react-redux';
import { setCustomer } from 'store/redux/modules/order/actions';
import { api } from 'services/api';

const useStyles = makeStyles({
  button: {
    padding: 0,
  },
  typography: {
    display: 'flex',
    alignItems: 'center',
  },
});

const Customer: React.FC = () => {
  const classes = useStyles();
  const { customer } = useSelector(state => state.order);
  const [dialogCustomerForm, setDialogCustomerForm] = useState(false);
  const [dialogCustomerSearch, setDialogCustomerSearch] = useState(false);
  const [textSearch, setTextSearch] = useState('');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setDialogCustomerSearch(!customer);
  }, [customer]);

  async function handleSetCustomer(customer: CustomerType | null) {
    if (!customer) {
      dispatch(setCustomer(null));
      return;
    }

    if (customer?.phone) {
      customer.phone = phoneFormat(customer.phone);
    }

    setLoading(true);

    const response = await api.get(`/distances/${customer.id}`);

    setLoading(false);

    dispatch(
      setCustomer({
        ...customer,
        addresses: response.data,
      })
    );
  }

  return (
    <>
      {dialogCustomerSearch && (
        <CustomerSearch
          handleSetCustomer={handleSetCustomer}
          setDialogCustomerForm={setDialogCustomerForm}
          setDialogCustomerSearch={setDialogCustomerSearch}
          textSearch={textSearch}
          setTextSearch={setTextSearch}
          addressesLoading={loading}
        />
      )}

      {dialogCustomerForm && (
        <CustomerForm
          handleChangeCustomer={handleSetCustomer}
          setDialogCustomerForm={setDialogCustomerForm}
          setDialogCustomerSearch={setDialogCustomerSearch}
          textSearch={textSearch}
        />
      )}

      <div>
        {customer ? (
          <CustomerSelected
            customer={customer}
            handleSetCustomer={handleSetCustomer}
            setDialogCustomerSearch={setDialogCustomerSearch}
          />
        ) : (
          <>
            <Typography gutterBottom variant="body1">
              Nenhum cliente selecionado.
            </Typography>
            <Button
              className={classes.button}
              variant="text"
              color="primary"
              onClick={() => setDialogCustomerSearch(!dialogCustomerSearch)}
            >
              Selecionar cliente
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default Customer;
