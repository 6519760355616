import React from 'react';
import { List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Customer } from 'types/customer';
import CustomerListItem from './CustomerListItem';

const useStyles = makeStyles(theme => ({
  list: {
    display: 'grid',
    gridGap: 6,
    gridTemplateColumns: '1fr',
  },
}));

interface CustomerListProps {
  customers: Customer[];
  handleSetCustomer(customer: Customer | null): Promise<void>;
}

function CustomerList({ customers, handleSetCustomer }: CustomerListProps) {
  const classes = useStyles();

  return (
    <List className={classes.list}>
      {customers.map(customer => (
        <CustomerListItem key={customer.id} handleSetCustomer={handleSetCustomer} customer={customer} />
      ))}
    </List>
  );
}

export default CustomerList;
