import React from 'react';
import { Typography, Button, makeStyles } from '@material-ui/core';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 0.7,
    gap: 30,
    textAlign: 'center',
  },
});

interface OrderConfirmationProps {
  handleSubmit(): void;
  saving: boolean;
}

function OrderConfirmation({ handleSubmit, saving }: OrderConfirmationProps) {
  const classes = styles();

  return (
    <div className={classes.container}>
      <Typography variant="h5">O pedido está pronto para ser criado</Typography>
      <Button disabled={saving} variant="contained" color="primary" onClick={handleSubmit}>
        Salvar Pedido
      </Button>
    </div>
  );
}

export default OrderConfirmation;
