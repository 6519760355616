import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Search } from '@material-ui/icons';
import { useOrder } from 'pages/board-management/registration/registration/hooks/useOrder';

interface OrderActionProps {
  setDialogResumeOrder(): void;
}

const OrderAction: React.FC<OrderActionProps> = ({ setDialogResumeOrder }) => {
  const { setShowSearchDialog } = useOrder();

  return (
    <>
      <Tooltip title="Pesquisar produtos">
        <IconButton color="inherit" onClick={() => setShowSearchDialog(true)}>
          <Search />
        </IconButton>
      </Tooltip>
      <Tooltip title="resumo do pedido">
        <IconButton color="inherit" onClick={setDialogResumeOrder}>
          <AssignmentIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default OrderAction;
