import React, { useState } from 'react';
import { List, ListItem, Typography, Tooltip, Button, IconButton, Menu, MenuItem } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  selected: {
    display: 'flex',
    border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    position: 'relative',
    alignItems: 'center',
    '&:focus': {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.25),
    },
  },
  listItem: {
    display: 'flex',
    backgroundColor: '#fff',
    border: '1px solid #f5f5f5',
    borderRadius: theme.shape.borderRadius,
    alignItems: 'center',
    position: 'relative',
  },
  list: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 6,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  listItemNewAddress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 120,
    backgroundColor: '#fff',
    borderRadius: theme.shape.borderRadius,
    border: `2px dashed ${theme.palette.primary.main}`,
  },
  listItemCustomerCollect: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 120,
    backgroundColor: '#fff',
    border: '1px solid #f5f5f5',
    borderRadius: theme.shape.borderRadius,
    alignItems: 'flex-start',
    position: 'relative',
    justifyContent: 'center',
  },
  address: {
    display: 'flex',
    alignItems: 'center',
    '@media (min-width:1280px) and (max-width:1360px)': {
      '&': {
        maxWidth: 300,
        display: 'block',
      },
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '90%',
      display: 'block',
    },
  },
  iconButton: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  starIcon: {
    marginLeft: 6,
    color: '#ffc107',
  },
  checkIcon: {
    position: 'absolute',
    right: 10,
    backgroundColor: '#fff',
    borderRadius: '50%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    alignSelf: 'flex-end',
  },
  actions: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  avatar: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.light}`,
    width: 50,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 7,
  },
  tax: {
    marginTop: 7,
  },
  collect: {
    fontSize: 20,
  },
}));

AddressesList.propTypes = {
  address: PropTypes.object.isRequired,
  selectAddress: PropTypes.func.isRequired,
  shipmentAddressId: PropTypes.number.isRequired,
  openDialogNewCustomerAddress: PropTypes.func.isRequired,
  handleOpenDialogCustomerEditAddress: PropTypes.func.isRequired,
  handleSetShipmentCustomerCollect: PropTypes.func.isRequired,
};

export default function AddressesList({
  addresses,
  selectAddress,
  shipmentAddressId,
  openDialogNewCustomerAddress,
  handleOpenDialogCustomerEditAddress,
  handleSetShipmentCustomerCollect,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIdAddress, setSelectedIdAddress] = useState(null);
  const restaurant = useSelector(state => state.restaurant);
  const order = useSelector(state => state.order);

  function handleClick(event, id) {
    event.stopPropagation();
    setSelectedIdAddress(id);
    setAnchorEl(event.currentTarget);
  }

  return (
    <div className={classes.container}>
      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() => {
            handleOpenDialogCustomerEditAddress(selectedIdAddress);
            setAnchorEl(null);
          }}
        >
          Alterar
        </MenuItem>
      </Menu>
      <List className={classes.list}>
        {addresses.map(address => (
          <ListItem
            onClick={() => selectAddress(address.id)}
            button
            className={address.id === shipmentAddressId ? classes.selected : classes.listItem}
            key={address.id}
          >
            <IconButton className={classes.iconButton} onClick={event => handleClick(event, address.id)}>
              <MoreVertIcon />
            </IconButton>
            <div className={classes.actions}>
              <Button
                variant="text"
                color="primary"
                size="small"
                onClick={event => {
                  event.stopPropagation();
                  handleOpenDialogCustomerEditAddress(address.id);
                }}
              >
                Alterar
              </Button>
            </div>
            <div>
              <Typography variant="h6" className={classes.address} gutterBottom>
                {address.address}, {address.number}
                {address.is_main === 1 && (
                  <Tooltip title="Endereço principal">
                    <StarIcon className={classes.starIcon} />
                  </Tooltip>
                )}
              </Typography>
              <Typography variant="body2">{address.district}</Typography>
              <Typography variant="body2">
                {address.city}, {address.region}
                {address.postal_code !== '00000000' && `, ${address.postal_code}`}
              </Typography>
              {address.distance > 0 && (
                <Typography variant="caption" color="textSecondary">
                  {address.formattedDistance}
                </Typography>
              )}
              {restaurant.configs.tax_mode === 'district' && !!address.area_region && address.area_region.tax > 0 && (
                <Typography color="textSecondary" className={classes.tax} variant="body2">
                  Será cobrado {address.area_region.formattedTax} de taxa de entrega
                </Typography>
              )}
              {restaurant.configs.tax_mode === 'distance' && !!address.distance_tax && address.distance_tax > 0 && (
                <Typography color="textSecondary" className={classes.tax} variant="body2">
                  Será cobrado {address.formattedDistanceTax} de taxa de entrega
                </Typography>
              )}
            </div>
            {address.id === shipmentAddressId && <CheckCircleIcon color="primary" className={classes.checkIcon} />}
          </ListItem>
        ))}
        <ListItem button className={classes.listItemCustomerCollect} onClick={handleSetShipmentCustomerCollect}>
          <Typography variant="h6">Retirada</Typography>
          <Typography variant="body2" color="textSecondary">
            Cliente retira no balcão
          </Typography>
          {order.shipment?.scheduled_at && (
            <Typography color="textSecondary">Agendado para as {order.shipment?.formattedScheduledAt}</Typography>
          )}
        </ListItem>
        <ListItem button className={classes.listItemNewAddress} onClick={openDialogNewCustomerAddress}>
          <Typography variant="h6">Adicionar endereço</Typography>
        </ListItem>
      </List>
    </div>
  );
}
