import { Menu, MenuItem } from '@material-ui/core';
import React, { Dispatch } from 'react';
import { useBoardControl } from '../hooks/useBoardControl';

interface BoardProductListMenuProps {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<HTMLButtonElement | null>;
}

const BoardProductListMenu: React.FC<BoardProductListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const context = useBoardControl();

  function handleDeleteClick() {
    context.setShowDialogDelete(true);
    setAnchorEl(null);
  }

  function handleDetailClick() {
    context.setShowDialogUpdate(true);
    setAnchorEl(null);
  }

  /*
  function openDialogProductSplit() {
    context.setShowDialogSplit(true);
    setAnchorEl(null);
  }
  */

  return (
    <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
      {context.movement?.is_open && <MenuItem onClick={handleDeleteClick}>Excluir</MenuItem>}
      <MenuItem onClick={handleDetailClick}>Detalhes</MenuItem>
    </Menu>
  );
};

export default BoardProductListMenu;
