import { TableTemplate } from 'types/tableTemplate';

export const ordersTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: 'AÇÕES',
    originalId: 'actions',
    width: 60,
    notFilterable: true,
    notSearchable: true,
  },
  {
    id: 'formattedSequence',
    description: 'ID',
    originalId: 'sequence',
    width: 60,
  },
  {
    id: 'dateDistance',
    description: 'TEMPO',
    originalId: 'dateDistance',
    width: 80,
    notSearchable: true,
  },
  {
    id: 'statusText',
    description: 'STATUS',
    originalId: 'statusText',
    width: 150,
  },
  {
    id: 'customer_name',
    description: 'NOME',
    originalId: 'customer_name',
    width: 300,
  },
  {
    id: 'shipmentMethod',
    description: 'MÉTODO',
    originalId: 'shipmentMethod',
    width: 60,
  },
  {
    id: 'shipmentAddress',
    description: 'ENDEREÇO',
    originalId: 'shipmentAddress',
    width: 300,
  },
  {
    id: 'paymentMethodName',
    description: 'FORMA DE PAGAMENTO',
    originalId: 'paymentMethodName',
    width: 150,
  },
  {
    id: 'paymentMode',
    description: 'TIPO DO PAGAMENTO',
    originalId: 'paymentMode',
    width: 150,
  },
  {
    id: 'formattedDiscount',
    description: 'TROCO',
    originalId: 'change',
    width: 60,
    notSearchable: true,
  },
  {
    id: 'formattedTotal',
    description: 'VALOR',
    originalId: 'total',
    width: 80,
  },
  {
    id: 'formattedIsLate',
    description: 'ATRASADO',
    originalId: 'formattedIsLate',
    width: 80,
  },
  {
    id: 'formattedCreatedAt',
    description: 'DATA',
    originalId: 'created_at',
    width: 150,
    notSearchable: true,
  },
];
