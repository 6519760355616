import { useState } from 'react';
import PaymentChange from './PaymentChange';
import PaymentListItem from './PaymentListItem';
import { useOrder } from 'pages/board-management/registration/registration/hooks/useOrder';
import List from 'components/list/List';

function PaymentList() {
  const [dialogChange, setDialogChange] = useState(false);
  const { handleNext, paymentMethods } = useOrder();

  function handleCloseDialog() {
    setDialogChange(false);
    handleNext();
  }

  return (
    <>
      {dialogChange && <PaymentChange onExited={handleCloseDialog} />}

      <List>
        {paymentMethods.map(
          paymentMethod =>
            paymentMethod.mode === 'offline' && (
              <PaymentListItem key={paymentMethod.id} paymentMethod={paymentMethod} setDialogChange={setDialogChange} />
            )
        )}
      </List>
    </>
  );
}

export default PaymentList;
