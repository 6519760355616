import React, { useState, useEffect, useRef } from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { postalCodeSearch } from 'api/PostalCodeSearch';
import PostalCodeInput from 'components/masked-input/PostalCodeInput';
import CustomerFormAction from '../CustomerFormAction';
import PropTypes from 'prop-types';
import CustomDialogForm from 'components/dialog/CustomDialogForm';
import { useSelector } from 'react-redux';
import { api } from 'services/api';
import { moneyFormat } from 'helpers/NumberFormat';
import * as yup from 'yup';
import { useMessaging } from 'hooks/messaging';
import InsideSaving from 'components/loading/InsideSaving';

const interval = 500;
let timer = null;

function CustomerFormAddress({ handleAddressSubmit, handleModalState, customerId, saving }) {
  const restaurant = useSelector(state => state.restaurant);
  const [loading, setLoading] = useState(false);
  const [postalCode, setPostalCode] = useState('');
  const [address, setAddress] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [district, setDistrict] = useState('');
  const [city, setCity] = useState('');
  const [region, setRegion] = useState('');
  const [cepValidation, setCepValidation] = useState(false);
  const [cepValidationText, setCepValidationText] = useState('');
  const { handleOpen } = useMessaging();
  const [regions, setRegions] = useState([]);
  const [validation, setValidation] = useState({});
  const [areaRegionId, setAreaRegionId] = useState(null);
  const inputNumberRef = useRef(null);

  useEffect(() => {
    if (!restaurant) return;

    if (restaurant.configs.tax_mode === 'district') {
      setLoading(true);
      api
        .get('/areaRegions')
        .then(response => {
          setRegions(
            response.data.regions.map(r => {
              r.formattedTax = moneyFormat(r.tax);
              return r;
            })
          );
        })
        .catch(() => {
          handleOpen('Não foi possível carregar os bairros');
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (restaurant.addresses.length > 0) {
      setCity(restaurant.addresses[0].city);
      setRegion(restaurant.addresses[0].region);
    }
  }, [restaurant, handleOpen]);

  function handleChangeCep(value) {
    setPostalCode(value);
    setCepValidation(false);
    setCepValidationText('');

    const newPostalCode = value.replace(/\D/g, '');

    clearTimeout(timer);

    if (newPostalCode.length === 0) return false;

    if (newPostalCode.length < 8) {
      setCepValidation(false);
      setCepValidationText('CEP inválido');
    }

    if (newPostalCode.length === 8)
      timer = setTimeout(() => {
        setLoading(true);
        postalCodeSearch(newPostalCode)
          .then(response => {
            if (response.data.erro) {
              setCepValidationText('CEP inexistente');
              setCepValidation(false);
            } else {
              const { data } = response;
              setAddress(data.logradouro);
              setDistrict(data.bairro);
              setRegion(data.uf);
              setCity(data.localidade);
              setComplement(data.complemento);
              setCepValidation(true);
              setCepValidationText('');
              if (inputNumberRef.current) inputNumberRef.current.focus();
            }
          })
          .catch(err => {
            setCepValidation(false);
            if (err.response) {
              alert(err.response.data.erro);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }, interval);
  }

  async function handleValidation() {
    const schema = yup.object().shape({
      complement: yup.string().nullable(),
      district: yup.string().test('check_config', 'Bairro é obrigatório', value => {
        if (restaurant.configs.tax_mode !== 'district') {
          return value !== '';
        } else return true;
      }),
      areaRegionId: yup.mixed().test('check_area', 'Bairro é obrigatório', value => {
        if (restaurant.configs.tax_mode === 'district') {
          return !!value;
        } else return true;
      }),
      number: yup.string().required('O número é obrigatório'),
      address: yup.string().required('O endereço é obrigatório'),
    });

    const data = {
      address,
      number,
      complement,
      district,
      region,
      city,
      areaRegionId,
      postal_code: !cepValidation || !restaurant.configs.use_postalcode ? '00000000' : postalCode,
    };

    try {
      await schema.validate(data);
      await handleSubmit();
    } catch (err) {
      setValidation({
        [err.path]: err.message,
      });
      throw new Error('validation fails');
    }
  }

  async function handleSubmit() {
    const data = {
      address,
      number,
      complement,
      district,
      region,
      city,
      postal_code: !cepValidation || !restaurant.configs.use_postalcode ? '00000000' : postalCode,
      customer_id: customerId,
      area_region_id: areaRegionId,
    };

    try {
      await handleAddressSubmit(data);
    } catch (err) {
      if (err.response) {
        handleOpen(err.response.data.error);
      }
      throw new Error(err);
    }
  }

  function handleDistrictSelectChange(e) {
    setAreaRegionId(e.target.value);
    setDistrict(regions.find(r => r.id === e.target.value).name);
  }

  return (
    <CustomDialogForm
      title="Cadastro de endereço"
      handleModalState={handleModalState}
      handleSubmit={handleValidation}
      closeOnSubmit
      async
      componentActions={<CustomerFormAction saving={saving} />}
      maxWidth="sm"
    >
      {saving && <InsideSaving />}
      <TextField
        label="CEP"
        placeholder="Digite o CEP"
        margin="normal"
        fullWidth
        value={postalCode}
        onChange={event => handleChangeCep(event.target.value)}
        helperText={loading ? 'Pesquisando...' : cepValidationText}
        error={!cepValidation && cepValidationText !== ''}
        InputProps={{
          inputComponent: PostalCodeInput,
        }}
        autoFocus
        disabled={loading}
      />
      <TextField
        error={!!validation.address}
        helperText={!!validation.address && validation.address}
        label="Endereço"
        placeholder="Digite o endereço"
        margin="normal"
        fullWidth
        value={address}
        onChange={event => setAddress(event.target.value)}
        required
      />
      <TextField
        inputRef={inputNumberRef}
        error={!!validation.number}
        helperText={!!validation.number && validation.number}
        label="Número"
        placeholder="Digite o número"
        margin="normal"
        fullWidth
        value={number}
        onChange={event => setNumber(event.target.value)}
        required
      />
      {restaurant.configs.tax_mode === 'district' ? (
        <TextField
          error={!!validation.areaRegionId}
          helperText={
            validation.areaRegionId
              ? validation.areaRegionId
              : 'Se o bairro não estiver na lista, é porque não entregamos na região'
          }
          select
          label="Selecione um bairro"
          fullWidth
          value={areaRegionId}
          onChange={event => handleDistrictSelectChange(event)}
          margin="normal"
        >
          {regions.map(region => (
            <MenuItem key={region.id} value={region.id}>
              {region.name} - {region.formattedTax} (taxa de entrega)
            </MenuItem>
          ))}
        </TextField>
      ) : (
        <TextField
          error={!!validation.district}
          helperText={!!validation.district && validation.district}
          label="Bairro"
          placeholder="Digite o bairro"
          margin="normal"
          fullWidth
          value={district}
          onChange={event => setDistrict(event.target.value)}
        />
      )}
      <TextField
        label="Complemento"
        placeholder="Digite o complemento"
        margin="normal"
        fullWidth
        value={complement}
        onChange={event => setComplement(event.target.value)}
      />
      <TextField
        label="Cidade"
        placeholder="Digite a cidade"
        margin="normal"
        fullWidth
        value={city}
        disabled
        required
      />
      <TextField
        label="Estado"
        placeholder="Digite o estado"
        margin="normal"
        fullWidth
        value={region}
        disabled
        required
      />
    </CustomDialogForm>
  );
}

CustomerFormAddress.propTypes = {
  handleAddressSubmit: PropTypes.func.isRequired,
  handleModalState: PropTypes.func.isRequired,
  customerId: PropTypes.number.isRequired,
  saving: PropTypes.bool.isRequired,
};

export default CustomerFormAddress;
