import { differenceInDays, differenceInHours, differenceInMinutes, formatDistanceStrict } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export function formatDistance(initialDate: Date, finalDate: Date): string {
  const diffInDays = differenceInDays(finalDate, initialDate);

  if (diffInDays >= 1) {
    const distance = formatDistanceStrict(initialDate, new Date(), {
      locale: ptBR,
      roundingMethod: 'ceil',
    });
    return distance;
  }

  const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');

  const hours = differenceInHours(finalDate, initialDate);
  const minutesTemp = hours * 60 - differenceInMinutes(finalDate, initialDate);
  const minutes = minutesTemp < 0 ? minutesTemp * -1 : minutesTemp;

  return `${zeroPad(hours, 2)}:${zeroPad(minutes, 2)}`;
}
