import React, { ReactNode, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { setRestaurant } from 'store/redux/modules/restaurant/actions';
import { setUser } from 'store/redux/modules/user/actions';
import { api } from 'services/api';
import { Restaurant } from 'types/restaurant';
import { AxiosError } from 'axios';

export type Tokens = {
  access_token: string;
  refresh_token: string;
};

export function removeTokens() {
  localStorage.removeItem('access-token');
  localStorage.removeItem('refresh-token');
}

export function getAccessToken() {
  return localStorage.getItem('access-token');
}

export function getRefreshToken() {
  return localStorage.getItem('refresh-token');
}

interface User {
  name: string;
}

interface AuthContextValue {
  login(email: string, password: string): Promise<void>;
  logout(): Promise<void>;
  checkEmail(email: string): Promise<User>;
  validate(): Promise<void>;
}

const AuthContext = React.createContext({} as AuthContextValue);

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const dispatch = useDispatch();

  const login = useCallback(
    async (email: string, password: string): Promise<void> => {
      try {
        const response = await api.post('/login', { email, password });

        dispatch(setRestaurant(response.data.data.restaurant));
        dispatch(setUser(response.data.data.user));
      } catch (err) {
        if (err instanceof AxiosError && err.response?.status === 401) {
          throw new Error('Usuário ou senha incorretos');
        }

        throw new Error('Não foi possível fazer o login');
      }
    },
    [dispatch]
  );

  const checkEmail = useCallback(async (email: string): Promise<User> => {
    try {
      const response = await api.get(`/user/show/${email}`);
      return response.data.data;
    } catch (err) {
      if (err instanceof AxiosError && err.response?.status === 401) {
        throw new Error('E-mail não encontrado');
      }

      throw err;
    }
  }, []);

  const logout = useCallback(async (): Promise<void> => {
    await api.post('/logout');
    removeTokens();
    dispatch(setRestaurant({} as Restaurant));
  }, [dispatch]);

  const validate = useCallback(async () => {
    await api.get('/auth/validate');
  }, []);

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        checkEmail,
        validate,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextValue {
  return useContext(AuthContext);
}

export default AuthProvider;
