import React from 'react';
import { TextField, Grid, MenuItem } from '@material-ui/core';
import CustomDialog from 'components/dialog/CustomDialog';
import { BoardOrdersQueryParams } from '../BoardOrders';
import KeyboardDatePicker from 'components/pickers/KeyboardDatePicker';

type OrdersSearchProps = {
  onExited(): void;
  handleQueryParamsChange(index: keyof BoardOrdersQueryParams, value: any): void;
  queryParams: BoardOrdersQueryParams;
};

const OrderSearch: React.FC<OrdersSearchProps> = ({ onExited, handleQueryParamsChange, queryParams }) => {
  return (
    <CustomDialog handleModalState={onExited} title="Buscar pedidos">
      <Grid container>
        <Grid item xs={12} md={6} lg={3} xl={2}>
          <TextField
            fullWidth
            margin="normal"
            select
            label="Status do pedido"
            required
            value={queryParams.status}
            onChange={event => handleQueryParamsChange('status', event.target.value)}
          >
            <MenuItem value="all">todos</MenuItem>
            <MenuItem value="o">aberto</MenuItem>
            <MenuItem value="a">aprovado</MenuItem>
            <MenuItem value="d">saiu para entrega</MenuItem>
            <MenuItem value="c">completo</MenuItem>
            <MenuItem value="x">cancelado</MenuItem>
          </TextField>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={3} xl={2}>
          <KeyboardDatePicker
            label="Data inicial"
            placeholder="Data inicial"
            autoOk
            value={queryParams.initial_date}
            onChange={date => handleQueryParamsChange('initial_date', date)}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3} xl={2}>
          <KeyboardDatePicker
            label="Data final"
            placeholder="Data final"
            autoOk
            value={queryParams.final_date}
            onChange={date => handleQueryParamsChange('final_date', date)}
            margin="normal"
            fullWidth
          />
        </Grid>
      </Grid>
    </CustomDialog>
  );
};

export default OrderSearch;
