import React, { Dispatch, SetStateAction } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { useSelector } from 'store/redux/selector';
import { useOrders } from '../hook/useOrders';

type OrderListMenuProps = {
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  anchorEl: HTMLButtonElement | null;
};

const OrderListMenu: React.FC<OrderListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const {
    selectedOrder,
    handleCancel,
    handleOrderStatus,
    setDialogPrintOrder,
    sendToPrintOrder,
    setDialogTrack,
    handleCreateReceipt,
  } = useOrders();

  const restaurant = useSelector(state => state.restaurant);

  function handleCancelClick() {
    handleCancel();
    setAnchorEl(null);
  }

  function handleShipmentClick() {
    if (!selectedOrder) return;
    if (!restaurant) return;

    handleOrderStatus();
    setAnchorEl(null);
  }

  function handlePrintOrderClick() {
    setDialogPrintOrder(true);
    setAnchorEl(null);
  }

  function handleTrackClick() {
    setDialogTrack(true);
    setAnchorEl(null);
  }

  function handleSendToPrintOrder() {
    sendToPrintOrder();
    setAnchorEl(null);
  }

  function handleConcludeOrder() {
    setAnchorEl(null);
    handleOrderStatus();
  }

  function handleAceptOrder() {
    handleOrderStatus();
    setAnchorEl(null);
  }

  function handleCreateReceiptClick() {
    handleCreateReceipt();
    setAnchorEl(null);
  }

  return (
    <>
      {selectedOrder && (
        <Menu open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
          {selectedOrder.status === 'o' ? (
            <MenuItem onClick={handleAceptOrder}>aceitar</MenuItem>
          ) : selectedOrder.status === 'a' ? (
            <MenuItem onClick={handleShipmentClick}>pronto para entregar</MenuItem>
          ) : (
            selectedOrder.status === 'd' && <MenuItem onClick={handleConcludeOrder}>Finalizar</MenuItem>
          )}

          {selectedOrder.status !== 'x' && selectedOrder.status !== 'c' && (
            <MenuItem onClick={handleCancelClick}>cancelar</MenuItem>
          )}

          {restaurant && !restaurant.configs.use_printer ? (
            <div>
              <MenuItem onClick={handlePrintOrderClick}>imprimir pedido</MenuItem>
            </div>
          ) : (
            <div>
              <MenuItem onClick={handleSendToPrintOrder}>imprimir pedido</MenuItem>
            </div>
          )}
          <MenuItem onClick={handleTrackClick}>histórico</MenuItem>
          <MenuItem disabled onClick={handleCreateReceiptClick}>
            emitir cupom fiscal
          </MenuItem>
        </Menu>
      )}
    </>
  );
};

export default OrderListMenu;
