import { createContext, useContext } from 'react';
import { Order } from 'types/order';
import { OrderStatusOptions } from 'types/orderStatus';
import { OrderList } from '../types/orderList';

export type OrdersContextValue = {
  selectedOrder: Order | null;
  orders: Order[];
  setSelectedOrder(order: OrderList | null): void;
  handleOrderStatus(status?: OrderStatusOptions): void;
  setDialogPrintOrder(state: boolean): void;
  handleCancel(): void;
  sendToPrintOrder(): void;
  setDialogTrack(state: boolean): void;
  handleCreateReceipt(): void;
};

const OrdersContext = createContext<OrdersContextValue>({} as OrdersContextValue);
export const OrdersContextProvider = OrdersContext.Provider;

export function useOrders(): OrdersContextValue {
  const context = useContext(OrdersContext);
  return context;
}
