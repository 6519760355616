import React, { useContext } from 'react';
import { ListItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DialogFullscreenContext } from 'components/dialog/DialogFullscreen';
import { Customer } from 'types/customer';
import { useOrder } from 'pages/board-management/registration/registration/hooks/useOrder';

const useStyles = makeStyles(theme => ({
  listItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: 10,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#fff',
    boxShadow: 'none',
    border: '1px solid #f5f5f5',
  },
  typo: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: 7,
  },
}));

interface CustomerListItemProps {
  customer: Customer;
  handleSetCustomer(customer: Customer | null): Promise<void>;
}

function CustomerListItem({ customer, handleSetCustomer }: CustomerListItemProps) {
  const classes = useStyles();
  const dialogContext = useContext(DialogFullscreenContext);
  const { handleNext } = useOrder();

  function handleClick(customer: Customer) {
    handleSetCustomer(customer)
      .then(() => {
        dialogContext.handleCloseDialog;
        handleNext();
      })
      .catch(err => console.error(err));
  }

  return (
    <ListItem onClick={() => handleClick(customer)} classes={{ root: classes.listItem }} button key={customer.id}>
      <Typography variant="caption" color="primary">
        Cliente {customer.id}
      </Typography>
      <Typography gutterBottom variant="h6">
        {customer.name}
      </Typography>
      <Typography gutterBottom variant="body2">
        {customer.phone}
      </Typography>
      {customer.addresses.map(address => (
        <Typography color="textSecondary" variant="body2" key={address.id}>
          {address.address}, {address.number}, {address.district}
        </Typography>
      ))}
    </ListItem>
  );
}

export default CustomerListItem;
